import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row, Col, Card, Button } from "react-bootstrap"
import Service from "./signature-service"
import * as styles from "../../styles/components/Signature/index.module.scss"

import { useIntl, Link } from "gatsby-plugin-react-intl"

const SignatureServices = ({ page }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSignatureServices {
        edges {
          node {
            description {
              raw
            }
            jsonIcon {
              file {
                url
              }
            }
            slug
            title
            specialMonitoring
          }
        }
      }
    }
  `)

  const intl = useIntl()

  const services = data.allContentfulSignatureServices.edges
  return (
    <Container className={`py-5 text-center my-5 ${page && "pt-0 mt-0"}`}>
      {!page && (
        <>
          <h1 className={`${styles.title} mb-4`}>
            {intl.formatMessage({ id: "signatureServices" })}
          </h1>
          <p className="m-0">
            Ease of access, prompt courteous service and responsiveness to our
            patients concerns will always be our primary goal. We strive to
            treat them professionally with compassion, respect and dignity
          </p>
        </>
      )}
      <Row
        xs={2}
        sm={3}
        md={4}
        lg={5}
        className="g-3 g-lg-5 mt-1 mb-5 justify-content-center"
      >
        {services
          .sort((a, b) => {
            return a.node.slug > b.node.slug ? 1 : -1
          })
          .map(service => {
            return (
              <Link
                to={`/signature-services/${service.node.slug}`}
                key={service.node.slug}
              >
                <Col>
                  <Card
                    className={`${styles.card} p-3 align-items-center justify-content-center`}
                  >
                    {service.node.title === "Free Vaccines" && (
                      <p className="small justify-self-end mb-0">
                        <strong>
                          • {intl.formatMessage({ id: "QualifyServices" })} •
                        </strong>
                      </p>
                    )}

                    {service.node.specialMonitoring && (
                      <p className="small justify-self-end mb-0">
                        <strong>
                          • {intl.formatMessage({ id: "specialMonitoring" })} •
                        </strong>
                      </p>
                    )}
                    <Service animationData={service} />
                    <Card.Title className="mt-4">
                      <h4 className={styles.title2}>
                        {intl.formatMessage({ id: service.node.title })}
                      </h4>
                    </Card.Title>
                  </Card>
                </Col>
              </Link>
            )
          })}
      </Row>
      <Col className={styles.item2}>
        <a href="https://pediatricafterhour.com/" target="_blank">
          <Button className="h3" variant="book-now">
            {intl.formatMessage({ id: "afterHours" })}
          </Button>
        </a>
      </Col>
    </Container>
  )
}

export default SignatureServices
