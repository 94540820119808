import React, { useEffect, useRef } from 'react'
import lottie from 'lottie-web'
import { Col } from 'react-bootstrap'
import axios from 'axios'
import * as styles from '../../styles/components/Signature/signature-service.module.scss'

const SignatureServices = function ({ animationData }) {
  const container = useRef(null)
  const getLottie = async () => {
    if (!animationData.node.jsonIcon) {
      return
    }

    const response = await axios.get(animationData.node.jsonIcon.file.url)
    await lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: response.data
    })
  }

  useEffect(() => {
    getLottie()

    return () => {
      lottie.destroy()
    }
  }, [])

  return (
    <Col
      key={animationData.node.slug}
      className={`${styles.item} text-center ${animationData.node.slug}`}
      ref={container}
      id={animationData.node.slug}
      style={{ maxWidth: '65px' }}
    />
  )
}

export default SignatureServices
