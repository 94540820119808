import React from "react"
import { Container } from "react-bootstrap"
import * as styles from "../styles/components/title.module.scss"

export default function Title({ title, subtitle, picture, date }) {
  return (
    <Container
      fluid
      className={`px-5 py-5 m-0 ${styles.wrapper} ${picture && styles.picture}`}
    >
      <h1 className={`${styles.title} my-5 mx-auto`}>{title}</h1>

      <h2 className={`${styles.subtitle}`}>{subtitle}</h2>

      <h3 className={`${styles.date}`}>{date}</h3>
    </Container>
  )
}
