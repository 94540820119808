import React from "react"
import Layout from "../components/layout"
import Title from "../components/title"
import SignatureServices from "../components/Signature/index"
import { Container } from "react-bootstrap"
import { useIntl, Link } from "gatsby-plugin-react-intl"

const SignatureServicesPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Title title={intl.formatMessage({ id: "signatureServices" })} />
      <Container className="text-center pt-5">
        <SignatureServices page={true} />
      </Container>
    </Layout>
  )
}

export default SignatureServicesPage
